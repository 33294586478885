<template>
    <div id="precios">
      <section class="section-price p-5">
        <div class="container">
          <!-- Título -->
          <div class="row justify-content-center mb-4 mb-lg-6 text-white">
            <div class="col-12 col-lg-9 text-center">
              <h2 class="fw-light mb-4 title">Elija una página para su negocio</h2>
            </div>
          </div>
  
          <!-- Precios de Landing Pages -->
          <div>
            <h3 class="text-white text-center mb-4">Planes de Landing Page</h3>
            <div class="row">
              <div
                class="col-12 col-lg-6 col-xl-3 d-flex align-items-stretch"
                v-for="plan in landingPagePlans"
                :key="plan.id"
              >
                <div class="card shadow-lg p-3 border-gray-300 rounded-md mb-3 px-2 text-center" style="width: 18rem;">
                  <div class="card-header border-0 bg-white p-3">
                    <h2 class="h3 text-primary display-2 mb-2">{{ plan.title }}</h2>
                    <p class="text-gray-600 text-center mb-6">{{ plan.description }}</p>
                    <span class="d-block">
                      <span class="display-5 text-dark fw-bold">
                        <span class="align-top font-medium pricing">$</span>
                        {{ plan.price }}
                      </span>
                      <span class="d-block text-gray font-small">/ Pesos</span>
                    </span>
                  </div>
                  <div class="card-body">
                    <ul class="list-unstyled">
                      <li class="list-item pb-4" v-for="feature in plan.features" :key="feature">{{ feature }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Precios de Marketing Digital -->
          <div>
            <h3 class="text-white text-center mt-5 mb-4">Planes de Marketing Digital</h3>
            <div class="row">
              <div
                class="col-12 col-lg-6 col-xl-3 d-flex align-items-stretch"
                v-for="plan in marketingPlans"
                :key="plan.id"
              >
                <div class="card shadow-lg p-3 border-gray-300 rounded-md mb-3 px-2 text-center" style="width: 18rem;">
                  <div class="card-header border-0 bg-white p-3">
                    <h2 class="h3 text-primary display-2 mb-2">{{ plan.title }}</h2>
                    <p class="text-gray-600 text-center mb-6">{{ plan.description }}</p>
                    <span class="d-block">
                      <span class="display-5 text-dark fw-bold">
                        <span class="align-top font-medium pricing">$</span>
                        {{ plan.price }}
                      </span>
                      <span class="d-block text-gray font-small">/ Pesos</span>
                    </span>
                  </div>
                  <div class="card-body">
                    <ul class="list-unstyled">
                      <li class="list-item pb-4" v-for="feature in plan.features" :key="feature">{{ feature }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: "Pricing",
    data() {
      return {
        landingPagePlans: [
          {
            id: 1,
            title: "Landing Page",
            price: "3,500",
            features: [
              "1 Dominio primer año",
              "Hosting Primer año",
              "Landing page",
              "Página de una sola sección",
              "Posicionamiento SEO",
              "Soporte Técnico primer año, incluye (Página web y Correos corporativos)",
            ],
          },
          {
            id: 2,
            title: "Básica",
            price: "6,400",
            features: [
              "Página a la medida de la empresa",
              "Página de una sola sección",
              "1 Dominio Primer año",
              "Hosting Primer año",
              "Botón de Whatsapp",
              "Enlace a Redes Sociales",
              "Indexación en Google Search Console",
            ],
          },
          {
            id: 3,
            title: "Profesional",
            price: "14,800",
            features: [
              "Página Web a la medida de la empresa",
              "5 Secciones",
              "1 Dominio Primer año",
              "Hosting Primer año",
              "Soporte Técnico primer año, incluye (Página web y Correos corporativos)",
              "10 Correos Personalizados",
              "Botón Whatsapp y Redes sociales",
              "Formulario de contacto",
              "Enlace a Redes Sociales",
              "Indexación en Google Search Console",
            ],
          },
          {
            id: 4,
            title: "E-commerce",
            price: "22,000",
            features: [
              "Diseñamos tú tienda en línea",
              "Pasarela de Pago (Mercado Pago, PayPal, Tarjetas de Crédito)",
              "Catálogo de Productos",
              "1 Dominio Primer año",
              "Hosting Primer año",
              "Soporte Técnico primer año, incluye (Página web y Correos corporativos)",
              "Botón Whatsapp y Redes sociales",
              "Formulario de contacto",
              "Indexación en Google Search Console",
            ],
          },
        ],
        marketingPlans: [
          {
            id: 1,
            title: "Básico",
            description: "Ideal para pequeños negocios (META: Facebook, Instagram y WhatsApp)",
            price: "3,400",
            features: [
              "Gestión de redes sociales (Facebook, Instagram y WhatsApp Business)",
              "Informe mensual",
              "12 publicaciones",
              "Configuración",
              "Creación de anuncios",
              "Optimización de anuncios",
              "Sugerido de pauta",
              "Reporte de resultados",
            ],
          },
          {
            id: 2,
            title: "Esencial",
            description: "Ideal para pequeños (Google Ads)",
            price: "3,400",
            features: [
              "Branding; Red de búsqueda, display y skids ads (Google Ads)",
              "Configuración",
              "Análisis de keywords",
              "Segmentación",
              "Creación de los anuncios (Leads o Branding)",
              "Sugerido de pauta",
              "Reporte de resultados",
            ],
          },
          {
            id: 3,
            title: "Avanzada",
            description: "Ideal para pequeños y medianas empresas (Google Ads)",
            price: "4,900",
            features: [
              "Branding; Red de búsqueda, display y skids ads (Google Ads)",
              "Configuración",
              "Análisis de keywords",
              "Segmentación",
              "Creación de los anuncios (Leads o Branding)",
              "Sugerido de pauta",
              "Reporte de resultados",
            ],
          },
          {
            id: 4,
            title: "Premium",
            description: "Ideal para todo tipo de empresas (Google Ads)",
            price: "7,900",
            features: [
              "Branding; Red de búsqueda, display y skids ads (Google Ads)",
              "Configuración",
              "Análisis de keywords",
              "Segmentación",
              "Creación de los anuncios (Leads o Branding)",
              "Sugerido de pauta",
              "Reporte de resultados",
            ],
          },
        ],
      };
    },
  };
  </script>

<style scoped>
    .section-price {
        background-color: #2E2D41;
    }

    .title {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    .text-primary {
        color: #F6971B !important;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 2em !important;
    }

    .align-top {
        vertical-align: top !important;
    }

    .ribbon {
        width: 130px;
        height: 32px;
        font-size: 12px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
        background: #4dbe3b;
        transform: rotate(45deg);
        position: absolute;
        right: -42px;
        top: 15px;
        padding-top: 7px; 
    }

    .font-medium {
        font-size: 1.25rem;
        font-weight: 300;
    }

    .fw-bold {
        font-weight: 600 !important;
    }

    .btn-primary {
        color: #fff;
        background-color: #242e4c;
        border-color: #242e4c;
        box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(23 31 56 / 8%) !important;
        border-radius: 1rem;
        transition: all .2s ease;
        font-size: 1rem;
        padding: .8rem;
    }

    .btn-secondary {
        color: #fff;
        background-color: #F6971B;
        border-color: #F6971B;
        box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(23 31 56 / 8%) !important;
        border-radius: 1rem;
        transition: all .2s ease;
        font-size: 1rem;
        padding: .8rem;
    }

    .card {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        max-width: 100% !important;
    }

    .card:hover {
        margin-top: -.25rem;
        margin-bottom: .25rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }

    .custom-checkbox .form-check-input {
        transform: scale(1.5);
        -webkit-transform: scale(1.5);
    }

    /* Hover Effects on Card */

    @media (min-width: 992px) {
        .card:hover {
            margin-top: -.25rem;
            margin-bottom: .25rem;
            box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.3);
        }

        .card:hover {
            opacity: 1;
        }
    }
</style>